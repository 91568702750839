<template>
  <v-card min-height="100vh" flat color="transparent" class="xw-full">
    <v-card flat class="xmin-h-[70vh] xpb-[60px]">
      <v-card-title class="xflex xflex-row xjustify-between xitems-center">
        <Filters
          :search="search"
          :value="selectedYearMonth"
          @change="navigate"
          type="monthly"
          :has-project="false"
          tab="pto"
        >
        </Filters>
      </v-card-title>
      <v-card-text>
        <PTOTable :loading="loading" :items="items"></PTOTable>
        <!-- <pre>{{ items }}</pre> -->
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import Filters from "../components/Filters.vue";
import PTOTable from "../components/PTOTable.vue";
export default {
  data() {
    return {
      loading: false,
      items: [],
      search: null,
      selectedYearMonth: moment().format("YYYY-MM"),
    };
  },
  watch: {
    "$route.params.year_month": {
      handler: function (val) {
        this.selectedYearMonth = val;
        val && this.fetch(val);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["user"]),
    selectedUser() {
      return this.$route.params.user_id;
    },
  },
  methods: {
    navigate(val) {
      this.$router
        .push({
          name: "app-user-pto-monthly",
          params: {
            year_month: val,
            pid: this.$route.params.pid,
            user_id: this.selectedUser,
          },
        })
        .catch(() => {});
    },
    fetch(year_month) {
      if (!this.selectedUser || !year_month) return;
      this.loading = true;
      this.$axios
        .get(`api/ptos/${this.selectedUser}/monthly/${year_month}`)
        .then(({ data }) => {
          this.items = data;
        })
        .finally(() => (this.loading = false));
    },
  },
  components: { Filters, PTOTable },
};
</script>

<style lang="scss" scoped></style>
